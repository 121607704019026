import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import EmojiRender from './EmojiRender';

export const NextRoundLink: React.FC<PropsWithChildren<{ to: string }>> = ({
  children,
  to,
}) => {
  return (
    <div className="flex w-full max-w-[530px] animate-pop mb-3 mt-3">
      <div className="p-2 w-full flex flex-col justify-center items-center gap-2 rounded-sm animate-reveal">
        <div className="p-2 flex flex-col bg-slate-500 bg-opacity-10 w-full text-black rounded-lg">
          <div className="w-full flex justify-center items-center mb-2 mt-1 font-bold gap-1">
            <EmojiRender text="★" className="inline-block text-orange-700" />
            <div className="inline-flex flex-row justify-center items-center gap-1 dark:text-white">
              {children}
            </div>
            <EmojiRender text="★" className="inline-block text-orange-700" />
          </div>
          <Link
            className="rounded-md text-md text-white font-bold p-1 text-lg flex w-full gap-2 items-center justify-center uppercase my-0.5 translate"
            style={{ backgroundColor: '#1a76d2' }}
            to={to}
          >
            <EmojiRender text="🎁" className="inline-block" />
            PLAY BONUS ROUND
          </Link>
        </div>
      </div>
    </div>
  );
};
