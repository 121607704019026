import {
  InteractiveToast,
  InteractiveToastProps,
} from '@pla324/teuteuf-interactive-toast';

interface IFrameWarningToastProps {
  show?: InteractiveToastProps['show'];
}

export const IFrameWarningToast = ({ show }: IFrameWarningToastProps) => {
  return (
    <InteractiveToast
      title={
        <div className="mb-3 text-2xl font-bold text-center text-gray-900 dark:text-white">
          🥵 ERROR! 🥵
        </div>
      }
      body={
        <div className="flex flex-col text-start text-base text-gray-900 dark:text-white gap-2">
          <p>
            {"Flagle's"} official website is{' '}
            <a
              href="https://www.flagle.io/?utm_campaign=iframe_popup"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              https://www.flagle.io/
            </a>{' '}
            It looks like {"you're"} playing on a website that has stolen it!
          </p>
          <p>
            These websites steal revenue from game creators by re-hosting their
            games and overlaying their own ads. Play Flagle on{' '}
            <a
              href="https://www.flagle.io/?utm_campaign=iframe_popup"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              flagle.io
            </a>
            , and {"you'll"} always have the latest version, fewer ads and help
            support the creators (i.e. us)
          </p>
          <p>Thanks,</p>
          <p>- Teuteuf Team</p>
        </div>
      }
      primaryButton={{
        label: 'Go There',
        textSize: 'text-lg',
        onClick: () =>
          window.open('https://www.flagle.io/?utm_campaign=iframe_popup'),
      }}
      maxWidth="max-w-sm"
      show={show}
    />
  );
};
