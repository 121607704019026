import styled from 'styled-components';

export const TitleBarDiv = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify = 'center' }) => justify};
`;

export const Title = styled.div`
  display: block;
  span {
    color: #1a76d2;
  }
`;
