import dayjs from 'dayjs';
import { useMemo } from 'react';

export function getDayString(): string {
  const date = dayjs();
  return `${date.format('YYYY-MM-DD')}-${date.day()}`;
}

export function useDailySeed(roundName = ''): string {
  return useMemo(
    () => `${getDayString()}${roundName ? `-${roundName}` : ''}`,
    [roundName],
  );
}
