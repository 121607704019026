import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';

const InnerContainer = styled.div`
  padding: 2em;
  justify-content: flex-start;
  overflow: auto;
`;

export const BaseModal = ({
  children,
  open,
  onClose,
  title,
}: {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 10000 }}
      className="dark:text-white"
    >
      <div className="absolute flex flex-col top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-auto max-w-[450px] max-h-[70vh] bg-white border-2 border-black shadow-lg w-[90%] outline-none dark:bg-[#121212] dark:text-white">
        <div className="w-full mt-auto mb-2.5 bottom-0 flex justify-between flex-row items-center gap-2.5 dark:text-white p-1 pl-8 border-b-2 border-black">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {title}
          </Typography>
          <Box>
            <IconButton
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
        <InnerContainer>{children}</InnerContainer>
      </div>
    </Modal>
  );
};

export default BaseModal;
